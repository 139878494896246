import { configureStore } from '@reduxjs/toolkit'
import appSlice from './slices/appSlice/appSlice'
import cartSlice from './slices/cartSlice/cartSlice'
import categorySlice from './slices/categorySlice/categorySlice'
import productSlice from './slices/productSlice/productSlice'

export const store = configureStore({
    reducer: {
        cart: cartSlice,
        product: productSlice,
        category: categorySlice,
        app: appSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})
