import { TextField } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

const ControlInput = ({
    control,
    name,
    pb = 1,
    pt = 0,
    autoComplete = 'off',
    sx = {},
    ...otherProps
}) => {
    const nameUpper = name.charAt(0).toUpperCase() + name.slice(1)

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    autoComplete={autoComplete}
                    label={nameUpper}
                    multiline={name === 'description'}
                    error={!!error}
                    variant="outlined"
                    helperText={error ? error.message : null}
                    placeholder={nameUpper}
                    fullWidth
                    FormHelperTextProps={{
                        sx: {
                            fontSize: '12px',
                        },
                    }}
                    sx={{
                        pb,
                        pt,
                        ...sx,
                        textarea: {
                            minHeight: '100px',
                        },
                    }}
                    {...otherProps}
                    {...field}
                />
            )}
        />
    )
}

export default ControlInput
