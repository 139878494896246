import { Box, Container } from '@mui/material'
import React from 'react'
import Cart from '../components/Cart/Cart'

const CartPage = () => {
    return (
        <Box pt={2}>
            <Container
                maxWidth="lg"
                sx={{ '	&.MuiContainer-root': { height: '100%' } }}
            >
                <Cart />
            </Container>
        </Box>
    )
}

export default CartPage
