import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { getProduct } from '../redux/slices/productSlice/productAsync'
import Product from '../components/Product/Product'
import { STATUS, getStatus } from '../common/utils/setStatus'
import LinearLoader from '../components/LinearLoader'

const SingleProductPage = () => {
    const dispatch = useDispatch()
    const { code } = useParams()
    const { currentProduct: product, status } = useSelector(
        ({ product }) => product
    )
    const productsCart = useSelector(({ cart }) => cart.products)

    const isLoading = getStatus(status, 'getProduct') === STATUS.loading
    const isProductCart = productsCart.find((el) => el.code === product?.code)

    useEffect(() => {
        dispatch(getProduct({ code }))
    }, [code])

    return (
        <>
            {isLoading && <LinearLoader position="relative" />}
            {!isLoading && product && (
                <Product product={product} isProductCart={!!isProductCart} />
            )}
        </>
    )
}

export default SingleProductPage
