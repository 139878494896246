import React from 'react'
import { Box, Container } from '@mui/material'
import HeaderBlock from './HeaderBlock'
import TabsBlock from './TabsBlock/TabsBlock'
import Footer from '../Footer/Footer'

const Product = ({ product }) => {
    return (
        <Container
            maxWidth="lg"
            sx={{
                '	&.MuiContainer-root': {
                    height: '100%',
                    pt: 5,
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
            disableGutters
        >
            <Box sx={{ flex: 1, pb: 8 }}>
                <HeaderBlock product={product} />
                <TabsBlock product={product} />
            </Box>
            <Footer />
        </Container>
    )
}

export default Product
