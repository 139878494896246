// import { useSelector } from 'react-redux'

export function useAuth() {
    // const { email, token, id, displayName, photoURL } = useSelector(
    //     (state) => state.user
    // )

    return {
        isAuth: false,
        // displayName,
        // photoURL,
        // email,
        // token,
        // id,
    }
}
