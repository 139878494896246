import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'

const ImgBlock = ({
    src,
    handleClickProduct,
    boxProps = {},
    sx = {},
    lazy = true,
}) => {
    const [width, setWidth] = useState(0)
    const boxRef = useRef(null)

    useEffect(() => {
        function handleResize() {
            if (boxRef.current) {
                setWidth(boxRef.current.offsetWidth)
            }
        }

        window.addEventListener('resize', handleResize)
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [boxRef])

    return (
        <Box
            ref={boxRef}
            {...boxProps}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: width,
                p: 1,
                img: {
                    maxWidth: '100%',
                    maxHeight: '100%',
                },
                ...sx,
            }}
            onClick={handleClickProduct}
        >
            <img src={src} alt="" loading={lazy && 'lazy'} />
        </Box>
    )
}

export default ImgBlock
