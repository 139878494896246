import { useSelector } from 'react-redux'
import SimpleSlider from '../components/Slider/SimpleSlider'
import ProductsBlock from '../components/ProductsBlock/ProductsBlock'
import { STATUS } from '../common/utils/setStatus'
import LinearLoader from '../components/LinearLoader'

const HomePage = () => {
    const { status, categories, subcategories } = useSelector(
        ({ category }) => category
    )
    const isLoadingCategory = status.find(
        (el) => el.name === 'getCategoriesSubCategories'
    )

    const isLoadingSuccess =
        categories.length > 0 &&
        subcategories.length > 0 &&
        isLoadingCategory?.status === STATUS.success

    return (
        <>
            <SimpleSlider />
            {isLoadingSuccess ? (
                <ProductsBlock homePage />
            ) : (
                <LinearLoader top="-23px" position="relative" />
            )}
        </>
    )
}

export default HomePage
