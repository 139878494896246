import React, { useEffect } from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { useDispatch } from 'react-redux'
import { ColorModeContext, useMode } from './theme/theme'
import { getCategoriesSubcategories } from './redux/slices/categorySlice/categoryAsync'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Layout from './components/Layout'
import './index.css'
import './i18n'

const App = () => {
    const dispatch = useDispatch()
    const [theme, colorMode] = useMode()

    useEffect(() => {
        dispatch(getCategoriesSubcategories())
    }, [])

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline enableColorScheme />
                <Layout />
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}

export default App
