import React, { useState } from 'react'
import { Box } from '@mui/material'
import Slider from 'react-slick'
import ImgBlock from '../ImgBlock'
import useMyTheme from '../../common/hooks/useMyTheme'

const ImageBlockSlider = ({ image }) => {
    const imageCut = image.slice(0, 4)
    const { mq } = useMyTheme()
    const [nav1, setNav1] = useState()
    const [nav2, setNav2] = useState()
    const [currentSlide, setCurrentSlide] = useState(0)
    
    const settingsMain = {
        arrows: false,
        slidesToShow: 1,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    }

    const settingsNav = {
        arrows: false,
        slidesToShow: imageCut.length > 1 ? imageCut.length : 1,
        swipeToSlide: true,
        focusOnSelect: true,
    }

    const sizeNav = mq ? '75px' : '100px'
    return (
        <Box
            sx={{
                width: '100%',
                div: {
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                },
            }}
        >
            <Slider
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}
                {...settingsMain}
            >
                {imageCut.map((img) => (
                    <ImgBlock
                        key={img}
                        src={img}
                        sx={{
                            maxWidth: '300px',
                            minHeight: '250px',
                            img: {
                                maxHeight: !mq ? '350px' : '250px',
                                height: '100%',
                            },
                        }}
                    />
                ))}
            </Slider>
            <Slider
                asNavFor={nav1}
                ref={(slider2) => setNav2(slider2)}
                {...settingsNav}
            >
                {imageCut.map((img, idx) => (
                    <ImgBlock
                        key={img}
                        src={img}
                        sx={{
                            maxWidth: sizeNav,
                            width: sizeNav,
                            height: sizeNav,
                            mb: 2,
                            position: 'relative',
                            '&:before': {
                                display: 'block',
                                position: 'absolute',
                                content: "''",
                                width: '100%',
                                height: '100%',
                                border: `1px solid ${
                                    idx === currentSlide && 'lightGrey'
                                }`,
                            },
                        }}
                    />
                ))}
            </Slider>
        </Box>
    )
}

export default ImageBlockSlider
