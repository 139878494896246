import * as React from 'react'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

export default function LinearLoader({ position = 'fixed', top = 0 }) {
    return (
        <Box sx={{ width: '100%', position, top, left: 0 }}>
            <LinearProgress />
        </Box>
    )
}
