import React from 'react'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'

const Description = ({ product }) => {
    return (
        <>
            <Typography variant="h3" component="span" color="initial">
                Specification:
            </Typography>
            <Box>
                {product.features.map((feature) => (
                    <Box key={feature.title} sx={{ display: 'flex', gap: 1 }}>
                        <Typography
                            variant="h6"
                            component="span"
                            color="initial"
                        >
                            {feature.title}:
                        </Typography>

                        <Typography
                            variant="h6"
                            component="span"
                            color="text.secondary"
                        >
                            {feature.feature}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </>
    )
}

export default Description
