import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { styled } from '@mui/material/styles'
import useMyTheme from '../common/hooks/useMyTheme'
import MyAppBar from './MyAppBar/MyAppBar'
import AppRouter from '../AppRouter'

const Indent = styled('div')(({ bottom }) => ({
    // eslint-disable-next-line no-nested-ternary
    paddingTop: !bottom && '77px',
    // paddingBottom: bottom && '24px',
}))

export default function Layout() {
    const { mq } = useMyTheme()

    return (
        <>
            <CssBaseline />
            <MyAppBar />
            <Indent mq={mq} />
            <AppRouter />
            <Indent mq={mq} bottom />
        </>
    )
}
