import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Container } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Description from './Description'
import AboutProduct from './AboutProduct'

const TabPanel = (props) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 2 }}>
                    <Typography variant='span'>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const TabsBlock = ({ product }) => {
    const [value, setValue] = React.useState(0)

    const handleChange = (_, newValue) => {
        setValue(newValue)
    }

    return (
        <Container>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Description" {...a11yProps(0)} />
                        <Tab label="About product" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Description product={product} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <AboutProduct product={product} />
                </TabPanel>
            </Box>
        </Container>
    )
}

export default TabsBlock
