/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentLink: '',
    openedSubmenu: '',
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        resetState: () => initialState,
        setLink(state, { payload }) {
            state.link = payload
        },
        setOpenedSubmenu(state, { payload }) {
            state.openedSubmenu = payload
        },
    },
})

export const { setLink, setOpenedSubmenu } = appSlice.actions

export default appSlice.reducer
