import { Box, Typography, Container } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import React, { useState, useEffect } from 'react'
import useMyTheme from '../../common/hooks/useMyTheme'
import sliderImg from '../../assets/img/slider-compress-4k.jpg'
import novaPost from '../../assets/img/novaPost.png'

const ItemSlicer = () => {
    const { mq: mq6 } = useMyTheme(600)
    const { mq: mq9 } = useMyTheme()
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        const img = new Image()
        img.src = sliderImg
        img.onload = () => setLoaded(true)
        // Обрабатываем случай, когда изображение не может загрузиться
        img.onerror = () => setLoaded(false)
    }, [])

    if (!loaded) return null

    return (
        <Box
            pl={5}
            pr={5}
            sx={{
                position: 'relative',
                '&:before': {
                    content: "''",
                    position: 'absolute',
                    display: 'block',
                    background: `url("${sliderImg}") 0 0/100% auto no-repeat`,
                    filter: 'brightness(60%)',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                },
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    zIndex: 1,
                }}
            >
                <Container maxWidth="lg">
                    <Grid2
                        container
                        spacing={2}
                        justifyContent="center"
                        pt={7}
                        pb={7}
                    >
                        <Grid2
                            sm={6}
                            display="flex"
                            flexDirection="column"
                            gap={4}
                            justifyContent="center"
                        >
                            <Typography
                                variant="h2"
                                color="white"
                                fontWeight={600}
                                maxWidth={mq6 ? '100%' : '80%'}
                                fontSize={mq9 ? '35px' : '45px'}
                            >
                                Швидка доставка товарів поштою, 1-3 дні
                            </Typography>
                        </Grid2>
                        {!mq6 && (
                            <Grid2
                                sm={6}
                                display="flex"
                                flexDirection="column"
                                gap={4}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Box>
                                    <img
                                        // eslint-disable-next-line max-len
                                        src={novaPost}
                                        alt=""
                                        height={!mq9 ? '400px' : '300px'}
                                        width={!mq9 ? '400px' : '300px'}
                                    />
                                </Box>
                            </Grid2>
                        )}
                    </Grid2>
                </Container>
            </Box>
        </Box>
    )
}

export default ItemSlicer
