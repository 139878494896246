import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Container } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import ItemProduct from './ItemProduct'
import LinearLoader from '../LinearLoader'

const Products = ({
    products,
    isLoading,
    handleNextProduct,
    isLoadingAddedProduct,
    onButtonLoadProduct
}) => {
    return (
        <Container maxWidth="false" sx={{ pt: 0, pb: 4 }}>
            <Grid container spacing={2} pb={4}>
                {products.length === 0 && !isLoading && (
                    <Grid xs={12}>Товарів немає</Grid>
                )}
                {isLoading && (
                    <Grid xs={12} minHeight="300px">
                        <LinearLoader position="relative" />
                    </Grid>
                )}
                {products.length > 0 &&
                    !isLoading &&
                    products.map((product) => {
                        return (
                            <Grid
                                xs={6}
                                sm={4}
                                md={4}
                                lg={3}
                                key={product.code}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <ItemProduct product={product} />
                            </Grid>
                        )
                    })}
            </Grid>
            {!isLoading && products.length > 0 && (
                <Box display="flex" justifyContent="center">
                    <LoadingButton
                        sx={{ height: '100%', fontWeight: 600 }}
                        onClick={handleNextProduct}
                        type="submit"
                        disabled={!onButtonLoadProduct}
                        loading={isLoadingAddedProduct}
                        variant="outlined"
                        size="large"
                    >
                        Завантажити ще 
                    </LoadingButton>
                </Box>
            )}
        </Container>
    )
}

export default Products
