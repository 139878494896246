import * as React from 'react'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { useNavigate } from 'react-router'
import Item from './Item/Item'
import OrderPanel from './OrderPanel'
import useMyTheme from '../../common/hooks/useMyTheme'
import cartEmpty from '../../assets/img/cartEmpty.png'

const style = {}

const Cart = () => {
    const { mq } = useMyTheme()
    const navigate = useNavigate()
    const products = useSelector(({ cart }) => cart.products)

    return (
        <Box sx={style} minHeight="100%">
            {products.length > 0 ? (
                <Grid container spacing={2}>
                    <Grid
                        xs={12}
                        md={7}
                        mt={mq ? 3 : 1}
                        sx={{ paddingBottom: 0 }}
                    >
                        {products.map((el, idx, arr) => {
                            return (
                                <Item
                                    key={el.code}
                                    product={el}
                                    last={idx === arr.length - 1}
                                />
                            )
                        })}
                    </Grid>

                    <Grid xs={12} md={5} sx={{ paddingBottom: 0 }}>
                        <OrderPanel products={products} />
                    </Grid>
                </Grid>
            ) : (
                <Box textAlign="center">
                    <Box maxWidth="200px" display="inline-block" pt={15}>
                        <img width="100%" src={cartEmpty} alt="" />
                    </Box>
                    <Typography
                        variant="h2"
                        color="text.primary"
                        fontWeight={600}
                        pb={5}
                    >
                        Oops... the basket is empty
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate('/')}
                        sx={{
                            width: '200px',
                            height: '50px',
                            fontSize: '25px',
                            color: 'white',
                        }}
                    >
                        Back
                    </Button>
                </Box>
            )}
        </Box>
    )
}

export default Cart
