import React from 'react'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { Box, Container, Typography } from '@mui/material'
import BuyButton from '../BuyButton'
import ImageBlockSlider from './ImageBlockSlider'

const HeaderBlock = ({ product }) => {
    return (
        <Container>
            <Grid container spacing={2}>
                <Grid
                    xs={12}
                    sm={6}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <ImageBlockSlider image={product.image} />
                </Grid>
                <Grid xs={12} sm={6} pb={3}>
                    <Typography
                        variant="h1"
                        color="initial"
                        fontWeight={600}
                        fontSize="25px"
                        letterSpacing="1px"
                    >
                        {product.name}
                    </Typography>
                    <Box pb={2}>
                        <Typography
                            variant="span"
                            component="span"
                            color="initial"
                            fontWeight={300}
                            fontSize="14px"
                        >
                            Code:{' '}
                        </Typography>
                        <Typography
                            variant="body"
                            color="initial"
                            fontWeight={300}
                            fontSize="14px"
                        >
                            {product.code}
                        </Typography>
                    </Box>
                    <Box pb={2}>
                        <Typography
                            variant="span"
                            color="initial"
                            fontWeight={400}
                            fontSize="14px"
                        >
                            Brand:{' '}
                        </Typography>
                        <Typography
                            variant="body"
                            color="initial"
                            fontWeight={600}
                            fontSize="14px"
                        >
                            {product.brand}
                        </Typography>
                    </Box>
                    <Typography
                        gutterBottom
                        variant="body"
                        component="p"
                        color="primary"
                        sx={{
                            fontSize: '35px',
                            fontWeight: 600,
                        }}
                    >
                        {product.price} ₴
                    </Typography>
                    <BuyButton
                        width="150px"
                        sxButton={{ padding: '13px 20px' }}
                        product={product}
                        content
                    />
                </Grid>
            </Grid>
        </Container>
    )
}

export default HeaderBlock
