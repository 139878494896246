import { Box, Container, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { useNavigate } from 'react-router'
import React from 'react'
import useMyTheme from '../../common/hooks/useMyTheme'
import logo from '../../assets/logo.svg'

const Footer = () => {
    const { mq } = useMyTheme('600')
    const navigate = useNavigate()
    const { colors } = useMyTheme()

    return (
        <Container maxWidth="false" disableGutters={mq} sx={{ pt: 0 }}>
            <Box
                sx={{
                    background: colors.primaryPink[600],
                    p: mq ? 3 : 5,
                    borderRadius: mq ? 0 : '20px',
                }}
            >
                <Grid container spacing={4}>
                    <Grid xs={12} sm={6}>
                        <Typography
                            color="white"
                            variant="h3"
                            component="div"
                            fontWeight="700"
                            sx={{
                                flexGrow: 1,
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                pb: 3,
                            }}
                            onClick={() => navigate('')}
                        >
                            <img width="40px" src={logo} alt="" />
                            KITSUNE
                        </Typography>
                        <Typography variant="subtitle1" color="white">
                            Магазин косметики {`"kitsune"`} - ваш надійний
                            помічник у догляді за шкірою, волоссям і нігтями. Ми
                            пропонуємо широкий вибір косметичних брендів з
                            різних країн світу, щоб задовольнити потреби кожного
                            клієнта. У нашому магазині ви знайдете продукти,
                            створені з урахуванням місцевих особливостей клімату
                            та культури догляду за собою. Ми гарантуємо високу
                            якість продукції та приємні ціни.
                        </Typography>
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <Typography
                            variant="h4"
                            component="p"
                            color="white"
                            pb={2}
                        >
                            Contact Us
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            component="p"
                            color="white"
                        >
                            Support phone / consultant: +380630550303
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            component="p"
                            color="white"
                        >
                            email: ellemod.ua@gmail.com
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default Footer
