import { useEffect, useRef } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { HOME_ROUTE } from './common/consts/ROUTES'
import { useAuth } from './common/hooks/useAuth'

import { privateRoutes, publicRoutes } from './routes'

function ScrollToTopOnMount() {
    const { pathname } = useLocation()
    const ref = useRef(null)

    useEffect(() => {
        ref.current?.scrollIntoView()
    }, [pathname])

    return <div ref={ref} />
}

const AppRouter = () => {
    const { isAuth } = useAuth()

    return isAuth ? (
        <>
            <ScrollToTopOnMount />
            <Routes>
                {privateRoutes.map(({ path, Component }) => (
                    <Route key={path} path={path} element={Component} />
                ))}

                <Route
                    path="*"
                    element={<Navigate to={HOME_ROUTE} replace />}
                />
            </Routes>
        </>
    ) : (
        <>
            <ScrollToTopOnMount />
            <Routes>
                {publicRoutes.map(({ path, Component }) => (
                    <Route key={path} path={path} element={Component} />
                ))}
                <Route
                    path="*"
                    element={<Navigate to={HOME_ROUTE} replace />}
                />
            </Routes>
        </>
    )
}

export default AppRouter
