import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import emailjs from '@emailjs/browser'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import './firebase'

import App from './App'

emailjs.init('PRUuy4BGSiuCbYLgx', 'template_5f9fhch')

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
)
