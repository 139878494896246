import {
    CART_ROUTE,
    HOME_ROUTE,
    LOGIN_ROUTE,
    PRODUCTS_ROUTE,
    PRODUCT_ROUTE,
} from './common/consts/ROUTES'
import Login from './page/LoginPage'
import HomePage from './page/HomePage'
import CartPage from './page/CartPage'
import ProductsPage from './page/ProductsPage'
import SingleProductPage from './page/SingleProductPage'

export const publicRoutes = [
    {
        path: LOGIN_ROUTE,
        Component: <Login />,
    },

    {
        path: CART_ROUTE,
        Component: <CartPage />,
    },
    {
        path: HOME_ROUTE,
        Component: <HomePage />,
    },
    {
        path: PRODUCTS_ROUTE,
        Component: <ProductsPage />,
    },
    {
        path: `${PRODUCTS_ROUTE}/:category`,
        Component: <ProductsPage />,
    },
    {
        path: `${PRODUCTS_ROUTE}/:category/:subcategory`,
        Component: <ProductsPage />,
    },
    {
        path: `${PRODUCT_ROUTE}/:code`,
        Component: <SingleProductPage />,
    },
]

export const privateRoutes = [...publicRoutes]
