import React from 'react'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import { Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { addProduct } from '../redux/slices/cartSlice/cartSlice'
                                                               
const BuyButton = ({
    product,
    width = '100%',
    sxButton = {},
    content = false,
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const cartProducts = useSelector(({ cart }) => cart.products)
    const check = cartProducts.find((el) => el?.code === product?.code)

    const handleAddProduct = () => {
        if (check) {
            navigate('/cart')
        } else {
            dispatch(addProduct(product))
        }
    }

    const iconContent = check ? (
        <ShoppingCartIcon
            sx={{
                color: 'white',
                fontSize: '23px',
                height: '100%',
            }}
        />
    ) : (
        <AddShoppingCartIcon
            sx={{
                color: 'primary',
                fontSize: '23px',
                height: '100%',
            }}
        />
    )
    
    const textContent = check ? (
        <Typography variant="p" color="initial" sx={{ color: 'white' }}>
            Go to cart
        </Typography>
    ) : (
        <Typography variant="p" color="initial" sx={{ color: 'primary' }}>
            Add to cart +
        </Typography>
    )

    return (
        <Button
            size="small"
            variant={check ? 'contained' : 'outlined'}
            onClick={handleAddProduct}
            // color="primary"
            sx={{
                ...sxButton,
                fontWeight: 600,
                fontSize: '13px',
                color: 'primary',
                width,
            }}
        >
            {content ? textContent : iconContent}
        </Button>
    )
}

export default BuyButton
