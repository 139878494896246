import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import {  Button, InputBase, styled } from '@mui/material'

const Search = styled('div')(({ theme }) => ({
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '35px',
    border: '1px solid lightGrey',
    '&:hover': {
        borderColor: theme.palette.primary.main,
    },
    '&:target': {
        borderColor: theme.palette.primary.main,
    },
    width: '500px',
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1.5, 3, 1.5, 0),
    },
}))
const StyledButton = styled(Button)(({ theme }) => ({
    color: 'white',
    padding: theme.spacing(0, 4, 0, 4),
    fontWeight: '600',
    borderRadius: '0px 0px 0px 0px',
    textTransform: 'initial',
    fontSize: '0.9rem',
}))

const SearchInput = () => {
    return (
        <Search>
            <SearchIconWrapper>
                <SearchIcon color="action" />
            </SearchIconWrapper>
            <StyledInputBase
                placeholder="Searching for..."
                inputProps={{ 'aria-label': 'search' }}
            />
            <StyledButton variant="contained" color="primary">
                Search
            </StyledButton>
        </Search>
    )
}

export default SearchInput
