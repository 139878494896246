import { Box, List, Typography } from '@mui/material'
import React from 'react'

const AboutProduct = ({ product }) => {
    return (
        <List>
            {product.descriptions.map((desc) => (
                <Box
                    key={desc.title}
                    sx={{
                        display: 'block',
                    }}
                >
                    <Typography variant="h3" color="text.secondary" pb={1}>
                        {desc.title}
                    </Typography>
                    {desc.paragraphs.map((p) => (
                        <Typography
                            key={p}
                            variant="p"
                            component="span"
                            color="initial"
                        >
                            {p}
                        </Typography>
                    ))}
                </Box>
            ))}
        </List>
    )
}

export default AboutProduct
