import { Box } from '@mui/material'
import React from 'react'
import Slider from 'react-slick'
import ItemSlicer from './ItemSlicer'

const customDots = (dots) => (
    <Box
        sx={{
            bottom: '15px',
            'li button': {
                width: '16px',
                height: '16px',
                borderRadius: '300px',
                margin: '0.25rem',
                cursor: 'pointer',
                position: 'relative',
                border: '1px solid #4E97FD',
                ':before': {
                    opacity: 0,
                    width: '9px',
                    height: ' 9px',
                    top: '50%',
                    left: '50%',
                    content: '""',
                    borderRadius: '300px',
                    position: 'absolute',
                    transform: 'translate(-50%, -50%) scaleX(1)',
                    backgroundColor: '#4E97FD',
                },
            },
        }}
    >
        {dots}
    </Box>
)

export default function SimpleSlider() {
    const settings = {
        dots: false, // true
        autoplay: false, // true
        infinite: true,
        speed: 500,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        appendDots: (dots) => customDots(dots),
    }

    return (
        <Box mb={3}>
            <Slider {...settings}>
                {[1, 2, 3].map((el) => (
                    <ItemSlicer key={el} />
                ))}
            </Slider>
        </Box>
    )
}
