import { createSlice } from '@reduxjs/toolkit'
import { setStatus } from '../../../common/utils/setStatus'

import { getCategoriesSubcategories } from './categoryAsync'

const initialState = {
    categories: [],
    subcategories: [],
    status: [],
}

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // getCategoriesSubCategories
        builder.addCase(getCategoriesSubcategories.pending, (state, action) => {
            setStatus(state, action)
        })
        builder.addCase(
            getCategoriesSubcategories.fulfilled,
            (state, action) => {
                setStatus(state, action)
                state.categories = action.payload.categories
                state.subcategories = action.payload.subcategories
            }
        )
        builder.addCase(
            getCategoriesSubcategories.rejected,
            (state, action) => {
                setStatus(state, action)
            }
        )
    },
})

// export const {} = categorySlice.actions

export default categorySlice.reducer
