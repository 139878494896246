import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    collection,
    getDocs,
    query,
    where,
    doc,
    limit,
    startAfter,
    getDoc,
} from 'firebase/firestore'
import { CATEGORIES, SUBCATEGORIES } from '../../../common/consts/consts'
import { db } from '../../../firebase'

export const getProduct = createAsyncThunk(
    'product/getProduct',
    async (data, { thunkAPI }) => {
        try {
            const docRef = doc(db, 'products', `${data.code}`)
            const docSnap = await getDoc(docRef)
            return { product: docSnap.data() }
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const getProducts = createAsyncThunk(
    'product/getProducts',
    async (data, { thunkAPI, getState }) => {
        try {
            const { limitGetProduct } = getState().product
            const { category, subcategory } = data
            const productsRef = collection(db, 'products')

            const subReg = subcategory && doc(db, SUBCATEGORIES, subcategory)
            const catReg = category && doc(db, CATEGORIES, category)

            let q = query(productsRef, limit(limitGetProduct))

            if (subcategory) {
                q = query(
                    productsRef,
                    where('subcategory', '==', subReg),
                    limit(limitGetProduct)
                )
            } else if (category) {
                q = query(
                    productsRef,
                    where('category', '==', catReg),
                    limit(limitGetProduct)
                )
            }

            const docSnapshot = await getDocs(q)

            const lastVisible = docSnapshot.docs[docSnapshot.docs.length - 1]

            const products = []
            docSnapshot?.forEach((doc) => products.push(doc.data()))
            return { products, lastVisible }
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)

export const addedProducts = createAsyncThunk(
    'product/addedProducts',
    async (data, { thunkAPI, getState }) => {
        try {
            const { limitGetProduct } = getState().product
            const { category, subcategory } = data
            const productsRef = collection(db, 'products')

            const subReg = subcategory && doc(db, SUBCATEGORIES, subcategory)
            const catReg = category && doc(db, CATEGORIES, category)
            const { lastVisibleProduct } = getState().product
            let q = query(
                productsRef,
                startAfter(lastVisibleProduct),
                limit(limitGetProduct)
            )

            if (subcategory) {
                q = query(
                    productsRef,
                    where('subcategory', '==', subReg),
                    startAfter(lastVisibleProduct),
                    limit(limitGetProduct)
                )
            } else if (category) {
                q = query(
                    productsRef,
                    where('category', '==', catReg),
                    startAfter(lastVisibleProduct),
                    limit(limitGetProduct)
                )
            }

            const docSnapshot = await getDocs(q)

            const lastVisible = docSnapshot.docs[docSnapshot.docs.length - 1]

            const products = []
            docSnapshot?.forEach((doc) => products.push(doc.data()))
            return { products, lastVisible }
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)
