import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Box, Container } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import MyMenu from '../Menu/MyMenu'
import {
    addedProducts,
    getProducts,
} from '../../redux/slices/productSlice/productAsync'
import useMyTheme from '../../common/hooks/useMyTheme'
import { STATUS, getStatus } from '../../common/utils/setStatus'
import Products from './Products'
import Footer from '../Footer/Footer'

const ProductsBlock = ({ homePage = false }) => {
    const { mq } = useMyTheme()
    const dispatch = useDispatch()
    const { category, subcategory } = useParams()
    const [onButtonLoadProduct, setOnButtonLoadProduct] = useState(false)
    const { products, status, limitGetProduct } = useSelector(
        ({ product }) => product
    )

    const isLoading = getStatus(status, 'getProducts') === STATUS.loading
    const isLoadingAddedProduct =
        getStatus(status, 'addedProducts') === STATUS.loading

    useEffect(() => {
        dispatch(getProducts({ category, subcategory }))
    }, [category, subcategory])

    useEffect(() => {
        setOnButtonLoadProduct(true)
    }, [products])

    const handleNextProduct = () => {
        setOnButtonLoadProduct(false)
        dispatch(addedProducts({ category, subcategory }))
    }

    return (
        <Container maxWidth="lg" sx={{ pt: 2, height: '100%' }} disableGutters>
            <Grid container sx={() => ({ height: '100%' })}>
                {!mq && (
                    <Grid width="270px">
                        <MyMenu homePage={homePage} />
                    </Grid>
                )}
                <Grid
                    xs
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box sx={{ flex: 1 }}>
                        <Products
                            limitGetProduct={limitGetProduct}
                            products={products}
                            isLoading={isLoading}
                            handleNextProduct={handleNextProduct}
                            isLoadingAddedProduct={isLoadingAddedProduct}
                            onButtonLoadProduct={onButtonLoadProduct}
                        />
                    </Box>
                    <Footer />
                </Grid>
            </Grid>
        </Container>
    )
}

export default ProductsBlock
