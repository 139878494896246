import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { useNavigate } from 'react-router'
import { Paper } from '@mui/material'
import { useDispatch } from 'react-redux'
import {
    addProduct,
    removeProduct,
} from '../../../redux/slices/cartSlice/cartSlice'
import ImgBlock from '../../ImgBlock'

const Item = ({ product }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleDelete = (remove) => {
        dispatch(removeProduct({ product, remove }))
    }
    const handleAdd = () => {
        dispatch(addProduct(product))
    }

    const handleClickProduct = () => {
        navigate(`/product/${product.code}`)
    }

    return (
        <Paper
            sx={{
                mb: 3,
            }}
            elevation={2}
        >
            <Grid container width="100%">
                <Grid width="125px">
                    <ImgBlock
                        src={product.image[0]}
                        handleClickProduct={handleClickProduct}
                        sx={{ cursor: 'pointer' }}
                    />
                </Grid>

                <Grid width="100%" xs p={1}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            gap: 2,
                        }}
                    >
                        <Typography
                            component="div"
                            variant="h4"
                            fontWeight={600}
                            pl="2px"
                            color="text.primary"
                            sx={{ cursor: 'pointer' }}
                            onClick={handleClickProduct}
                        >
                            {product.name}
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                variant="p"
                                color="text.secondary"
                                component="div"
                                fontSize="16px"
                                fontWeight={400}
                                pl="2px"
                            >
                                {product.price} $ x{product.count}
                            </Typography>
                            <Typography
                                variant="p"
                                color="primary"
                                component="div"
                                fontSize="16px"
                                fontWeight={500}
                                pl="2px"
                            >
                                {product.price * product.count} $
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                            }}
                        >
                            <IconButton
                                aria-label=""
                                sx={{ padding: '2px' }}
                                disabled={product.count <= 1}
                                onClick={() => handleDelete(false)}
                            >
                                <IndeterminateCheckBoxOutlinedIcon
                                    sx={(theme) => ({
                                        fontSize: '27px',
                                        color:
                                            product.count > 1 &&
                                            theme.palette.primary.main,
                                    })}
                                />
                            </IconButton>

                            <Typography
                                id="count"
                                variant="h4"
                                textAlign="center"
                            >
                                {product.count}
                            </Typography>
                            <IconButton
                                aria-label=""
                                sx={{ padding: '2px' }}
                                onClick={() => handleAdd()}
                            >
                                <AddBoxOutlinedIcon
                                    fontSize="small"
                                    sx={(theme) => ({
                                        fontSize: '27px',
                                        color: theme.palette.primary.main,
                                    })}
                                />
                            </IconButton>
                        </Box>
                    </Box>
                </Grid>
                <Grid width="50px">
                    <IconButton
                        onClick={() => handleDelete(true)}
                        aria-label="delete product"
                        p={0}
                        sx={{ m: 1 }}
                    >
                        <CloseIcon sx={{ fontSize: '20px' }} />
                    </IconButton>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default Item
