import { createAsyncThunk } from '@reduxjs/toolkit'
import { collection,  getDocs } from 'firebase/firestore'
import { CATEGORIES, SUBCATEGORIES } from '../../../common/consts/consts'

import { db } from '../../../firebase'

export const getCategoriesSubcategories = createAsyncThunk(
    'category/getCategoriesSubCategories',
    async (_, { thunkAPI }) => {
        try {
            const querySnapCAT = await getDocs(collection(db, CATEGORIES))
            const querySnapSUB = await getDocs(collection(db, SUBCATEGORIES))

            const categories = []
            const subcategories = []

            querySnapCAT.forEach((doc) => categories.push(doc.data()))
            querySnapSUB.forEach((doc) => subcategories.push(doc.data()))

            return { categories, subcategories }
        } catch (e) {
            return thunkAPI.rejectWithValue(e)
        }
    }
)
