/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    products: JSON.parse(window.localStorage.getItem('cartProducts')) || [],
}

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        resetState: () => initialState,
        addProduct(state, { payload }) {
            const check = state.products.find((el) => el.code === payload.code)
            if (check) {
                const res = state.products.map((el) => {
                    if (payload.code === el.code) {
                        return {
                            ...el,
                            count: el.count + 1,
                        }
                    }
                    return el
                })
                window.localStorage.setItem('cartProducts', JSON.stringify(res))
                state.products = res
            } else {
                const res = [...state.products, { ...payload, count: 1 }]
                window.localStorage.setItem('cartProducts', JSON.stringify(res))
                state.products = res
            }
        },
        removeProduct(state, { payload }) {
            const check = state.products.find(
                (el) => el.code === payload.product.code
            )

            if (check && check.count > 1 && !payload.remove) {
                const res = state.products.map((el) => {
                    if (payload.product.code === el.code) {
                        return {
                            ...el,
                            count: el.count - 1,
                        }
                    }
                    return el
                })
                window.localStorage.setItem('cartProducts', JSON.stringify(res))
                state.products = res
            } else {
                const res = state.products.filter(
                    (el) => el.code !== payload.product.code
                )
                window.localStorage.setItem('cartProducts', JSON.stringify(res))
                state.products = res
            }
        },
    },
})

export const { resetState, addProduct, removeProduct } = cartSlice.actions

export default cartSlice.reducer
